var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
define(["require", "exports", "@os/os-common/lib/ioc", "@os/os-common/lib/services/chatbot/ChatbotService"], function (require, exports, ioc_1, ChatbotService_1) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    exports.showChatbot = exports.updateChatbotConfig = exports.changeChatbotLanguage = exports.initChatbot = void 0;
    const chatbotService = ioc_1.container.get(ChatbotService_1.ChatbotService);
    const initChatbot = (channelId, botClientAuthEnabled) => chatbotService.init({ channelId, isClientAuthEnabled: botClientAuthEnabled });
    exports.initChatbot = initChatbot;
    const changeChatbotLanguage = (language) => {
        chatbotService.changeLanguage(language);
    };
    exports.changeChatbotLanguage = changeChatbotLanguage;
    const updateChatbotConfig = (user) => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b;
        const [firstName, lastName] = (_b = (_a = user.displayName) === null || _a === void 0 ? void 0 : _a.split(" ")) !== null && _b !== void 0 ? _b : ["", ""];
        try {
            yield chatbotService.updateConfig({ country: user.defaultCountry, firstName, lastName, email: user.email });
        }
        catch (err) {
            console.error("Unable to update chatbot config.", err);
        }
    });
    exports.updateChatbotConfig = updateChatbotConfig;
    const showChatbot = (show) => {
        const chatbotWrapper = document.getElementsByClassName("oda-chat-wrapper")[0];
        if (chatbotWrapper) {
            chatbotWrapper.style.display = show ? "block" : "none";
        }
    };
    exports.showChatbot = showChatbot;
});
