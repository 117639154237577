define(["require", "exports", "../../utils/environment", "../../utils/pluginUrl", "../app"], function (require, exports, environment_1, pluginUrl_1, app_1) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    exports.reducer = exports.setError = exports.setPluginUnhandledException = exports.setWindowHeight = exports.setContentLoaded = exports.setLanguage = exports.setPreFetchUrl = exports.setPluginLiftSandbox = exports.setPluginUrl = exports.setPlugin = exports.initialState = void 0;
    exports.initialState = {
        plugin: { options: { liftSandbox: false } },
        bootstrap: {},
        preFetch: {},
        languageCode: "",
        isContentLoaded: false,
        windowHeight: "",
        pluginUnhandledException: undefined,
        error: undefined
    };
    const setPlugin = (data) => ({ type: "setPlugin", data });
    exports.setPlugin = setPlugin;
    const setPluginUrl = (data) => ({ type: "setPluginUrl", data });
    exports.setPluginUrl = setPluginUrl;
    const setPluginLiftSandbox = (data) => ({ type: "setPluginLiftSandbox", data });
    exports.setPluginLiftSandbox = setPluginLiftSandbox;
    const setPreFetchUrl = (data) => ({ type: "setPreFetchUrl", data });
    exports.setPreFetchUrl = setPreFetchUrl;
    const setLanguage = (data) => ({ type: "setLanguage", data });
    exports.setLanguage = setLanguage;
    const setContentLoaded = () => ({ type: "setContentLoaded" });
    exports.setContentLoaded = setContentLoaded;
    const setWindowHeight = (data) => ({ type: "setWindowHeight", data });
    exports.setWindowHeight = setWindowHeight;
    const setPluginUnhandledException = (data) => ({ type: "setPluginUnhandledException", data });
    exports.setPluginUnhandledException = setPluginUnhandledException;
    const setError = (data) => ({ type: "setError", data });
    exports.setError = setError;
    const reducer = (state, action) => {
        var _a, _b, _c, _d, _e;
        (0, environment_1.debug)("[OCC] reducer action", JSON.stringify({ state, action }));
        const newState = (newState) => {
            (0, environment_1.debug)("[OCC] reducer new state", JSON.stringify({ newState }));
            return newState;
        };
        switch (action.type) {
            case "setPlugin":
                if (action.data) {
                    const hasSubstituteUrl = (_a = action.data.url) === null || _a === void 0 ? void 0 : _a.split("/").some(ps => ps.match(/^:.+/) || ps.startsWith("*"));
                    const hasPreFetchUrl = (_b = action.data.options) === null || _b === void 0 ? void 0 : _b.httpHeaderAuth;
                    const formattedUrl = (0, app_1.formatLanguageTemplate)(action.data.url, state.languageCode || "en");
                    return newState(Object.assign(Object.assign({}, exports.initialState), { plugin: {
                            name: action.data.name,
                            rawUrl: (0, pluginUrl_1.appendPluginSearchParams)(!hasPreFetchUrl && !hasSubstituteUrl ? action.data.url : undefined),
                            url: (0, pluginUrl_1.appendPluginSearchParams)(!hasPreFetchUrl && !hasSubstituteUrl ? formattedUrl : undefined),
                            substituteUrl: !hasPreFetchUrl && hasSubstituteUrl ? action.data.url : undefined,
                            path: action.data.path,
                            allowedOrigins: ((_c = action.data.options) === null || _c === void 0 ? void 0 : _c.httpHeaderAuth) && formattedUrl ? [new URL(formattedUrl).origin] : [],
                            options: Object.assign(Object.assign({}, state.plugin.options), action.data.options)
                        }, languageCode: state.languageCode, bootstrap: {
                            rawUrl: action.data.bootstrapUrl,
                            url: (0, app_1.formatLanguageTemplate)(action.data.bootstrapUrl, state.languageCode || "en")
                        }, preFetch: {
                            rawUrl: (0, pluginUrl_1.appendPluginSearchParams)(hasPreFetchUrl && !hasSubstituteUrl ? action.data.url : undefined),
                            url: (0, pluginUrl_1.appendPluginSearchParams)(hasPreFetchUrl && !hasSubstituteUrl ? formattedUrl : undefined),
                            substituteUrl: hasPreFetchUrl && hasSubstituteUrl ? action.data.url : undefined,
                        } }));
                }
                return state;
            case "setPluginUrl":
                const pluginRawUrl = (0, pluginUrl_1.appendPluginSearchParams)((!((_d = state.plugin.options) === null || _d === void 0 ? void 0 : _d.httpHeaderAuth) || ((_e = state.plugin.options) === null || _e === void 0 ? void 0 : _e.httpHeaderAuth) && state.preFetch.url) ? action.data : undefined);
                return newState(Object.assign(Object.assign({}, state), { plugin: Object.assign(Object.assign({}, state.plugin), { rawUrl: pluginRawUrl, url: (0, app_1.formatLanguageTemplate)(pluginRawUrl, state.languageCode || "en"), substituteUrl: undefined }) }));
            case "setPluginLiftSandbox":
                return newState(Object.assign(Object.assign({}, state), { plugin: Object.assign(Object.assign({}, state.plugin), { options: Object.assign(Object.assign({}, state.plugin.options), { liftSandbox: action.data }) }) }));
            case "setPreFetchUrl":
                const rawUrl = (0, pluginUrl_1.appendPluginSearchParams)(action.data);
                return newState(Object.assign(Object.assign({}, state), { preFetch: {
                        rawUrl,
                        url: (0, app_1.formatLanguageTemplate)(rawUrl, state.languageCode || "en"),
                        substituteUrl: undefined
                    } }));
            case "setLanguage":
                if (action.data) {
                    const updatedState = Object.assign(Object.assign({}, state), { plugin: Object.assign(Object.assign({}, state.plugin), { url: (0, app_1.formatLanguageTemplate)(state.plugin.rawUrl, action.data) }), preFetch: Object.assign(Object.assign({}, state.preFetch), { url: (0, app_1.formatLanguageTemplate)(state.preFetch.rawUrl, action.data) }), languageCode: action.data });
                    if (!state.bootstrap.params) {
                        updatedState.bootstrap = Object.assign(Object.assign({}, state.bootstrap), { url: (0, app_1.formatLanguageTemplate)(state.bootstrap.rawUrl, action.data), params: { languageCode: action.data } });
                    }
                    return newState(updatedState);
                }
                return newState(state);
            case "setContentLoaded":
                return newState(Object.assign(Object.assign({}, state), { isContentLoaded: true }));
            case "setWindowHeight":
                return newState(Object.assign(Object.assign({}, state), { windowHeight: action.data }));
            case "setPluginUnhandledException":
                return newState(Object.assign(Object.assign({}, state), { pluginUnhandledException: action.data }));
            case "setError":
                return newState(Object.assign(Object.assign({}, state), { error: { type: action.data } }));
            default:
                (0, environment_1.debug)("[OCC] Invalid reducer action", action);
                return newState(state);
        }
    };
    exports.reducer = reducer;
});
