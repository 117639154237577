define(["require", "exports", "preact/jsx-runtime", "preact", "preact/hooks", "../hooks/useQuery", "./userProvider"], function (require, exports, jsx_runtime_1, preact_1, hooks_1, useQuery_1, userProvider_1) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    exports.HelpProvider = exports.HelpContext = void 0;
    exports.HelpContext = (0, preact_1.createContext)({
        helpContent: undefined, helpContentOptions: undefined, selectedContent: undefined,
        refreshHelpContent: undefined, onContentSelected: undefined
    });
    const HelpProvider = ({ accessToken, setAuthenticationError, children }) => {
        const { language } = (0, hooks_1.useContext)(userProvider_1.UserContext);
        const [_helpContent, setHelpContent] = (0, hooks_1.useState)();
        const [_helpContentOptions, setHelpContentOptions] = (0, hooks_1.useState)();
        const [topicId, setTopicId] = (0, hooks_1.useState)();
        const [_selectedContent, setSelectedContent] = (0, hooks_1.useState)();
        const { result } = (0, useQuery_1.useHelpTopicQuery)(topicId, language)(accessToken, { setAuthenticationError });
        const refreshHelpContent = (htId) => {
            if (htId !== topicId) {
                setTopicId(htId);
            }
            if (htId === undefined) {
                setHelpContent(undefined);
                setHelpContentOptions(undefined);
            }
        };
        (0, hooks_1.useEffect)(() => {
            var _a;
            if (result) {
                setHelpContent(Object.assign(Object.assign({}, result), { content: result.content.slice(0, 6) }));
                setHelpContentOptions((_a = result.content) === null || _a === void 0 ? void 0 : _a.slice(0, 6).map(c => ({ label: c.title, value: c.id })));
            }
        }, [result]);
        return ((0, jsx_runtime_1.jsx)(exports.HelpContext.Provider, { value: {
                helpContent: _helpContent, helpContentOptions: _helpContentOptions, selectedContent: _selectedContent,
                refreshHelpContent, onContentSelected: setSelectedContent
            }, children: children }));
    };
    exports.HelpProvider = HelpProvider;
});
