var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
define(["require", "exports", "preact/jsx-runtime", "@os/os-communication-interface/lib/runtime/FrameworkPluginRuntime", "@os/os-components/lib/OsHooks/useTranslationFor", "preact/compat", "preact/hooks", "react-router-dom", "../alertMessage", "../common/errorPage", "../common/spinner", "../../contexts/helpProvider", "../../contexts/recentActivityProvider", "../../contexts/searchProvider", "../../contexts/userProvider", "../../hooks/useConfigOverride", "../../hooks/useMutation", "../../hooks/useQuery", "../../utils/analytics", "../../utils/chatbot", "../../utils/environment", "../../utils/ogl", "./reducer", "./index.scss"], function (require, exports, jsx_runtime_1, FrameworkPluginRuntime_1, useTranslationFor_1, compat_1, hooks_1, react_router_dom_1, alertMessage_1, errorPage_1, spinner_1, helpProvider_1, recentActivityProvider_1, searchProvider_1, userProvider_1, useConfigOverride_1, useMutation_1, useQuery_1, analytics_1, chatbot_1, environment_1, ogl_1, reducer_1) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    exports.ErrorPageContent = exports.Content = void 0;
    const importHomePage = (environment) => (0, compat_1.lazy)(() => new Promise((resolve_1, reject_1) => { require([`./${environment}HomePage`], resolve_1, reject_1); }).then(__importStar));
    const nonce = crypto.randomUUID();
    const frameId = `pluginFrame-${nonce}`;
    const createFrame = (parentId, liftSandbox) => {
        const parentNode = document.getElementById(parentId);
        if (parentNode) {
            const frame = document.createElement("iframe");
            frame.setAttribute("id", frameId);
            frame.setAttribute("scrolling", "no");
            frame.setAttribute("frameborder", "0");
            if (!liftSandbox) {
                frame.setAttribute("sandbox", "allow-forms allow-popups allow-popups-to-escape-sandbox allow-scripts allow-same-origin allow-downloads allow-modals");
            }
            frame.style.width = "100%";
            frame.style.minHeight = "100%";
            return parentNode.appendChild(frame);
        }
        return null;
    };
    const openPlugin = (url, pluginPath, allowedOrigins) => {
        (0, environment_1.debug)("[OCC] opening plugin", { url, pluginPath });
        const start = performance.now();
        return (0, FrameworkPluginRuntime_1.getPluginRuntime)().openPlugin({
            url,
            pluginPath,
            allowedOrigins,
            debug: environment_1.isDebugOn,
            embedded: {
                frameId
            }
        })
            .then(() => routePlugin(pluginPath, window.location.pathname))
            .catch(ex => {
            console.error("[OCC] Exception during opening plugin.", ex);
            throw ex;
        })
            .finally(() => {
            const end = performance.now();
            console.log(`[OCC] Openening plugin in ${end - start} ms.`);
        });
    };
    const routePlugin = (pluginPath, locationPath) => {
        var _a;
        const routeUrl = (_a = locationPath.split(pluginPath)[1]) !== null && _a !== void 0 ? _a : "";
        (0, FrameworkPluginRuntime_1.getPluginRuntime)().route(routeUrl);
    };
    const Content = ({ plugin, finalPlugins, environment, accessToken, navigateRef, pluginRef, menuOpened, isError, handleNavigation }) => {
        var _a, _b, _c;
        const [{ plugin: pluginState, bootstrap, preFetch, isContentLoaded, windowHeight, pluginUnhandledException, error }, dispatch] = (0, hooks_1.useReducer)(reducer_1.reducer, reducer_1.initialState);
        const errorTitle = (0, useTranslationFor_1.useTranslationFor)("errorTitle");
        const errorMessage = (0, useTranslationFor_1.useTranslationFor)("errorMessage");
        // Plugin runtime init
        const { append: appendRecentActivity } = (0, hooks_1.useContext)(recentActivityProvider_1.RecentActivityContext);
        const { searchValue, setSearchValue } = (0, hooks_1.useContext)(searchProvider_1.SearchContext);
        const { refreshHelpContent, onContentSelected } = (0, hooks_1.useContext)(helpProvider_1.HelpContext);
        const addRecentActivity = (0, hooks_1.useCallback)(activity => {
            appendRecentActivity({
                plugin: plugin === null || plugin === void 0 ? void 0 : plugin.name,
                label: `recentActivity.${activity.label}`,
                entityId: activity.entityId,
                url: window.location.origin + activity.url,
                creationDate: new Date().toISOString(),
                entityAmount: activity.entityAmount,
                entityCurrency: activity.entityCurrency,
                entityDate: activity.entityDate
            });
        }, [appendRecentActivity, plugin]);
        const navigate = (0, react_router_dom_1.useNavigate)();
        const navigationHandler = (0, hooks_1.useCallback)((url) => {
            const urlObj = new URL(url, window.location.origin);
            if (urlObj.origin !== window.location.origin) {
                handleNavigation({
                    labelKey: "",
                    path: urlObj.href
                })();
            }
            else {
                handleNavigation({
                    labelKey: "",
                    pluginName: "unknown",
                    appendPath: urlObj.pathname + urlObj.search
                })();
                window.scrollTo({ top: 0, behavior: "smooth" });
                dispatch((0, reducer_1.setWindowHeight)(""));
            }
        }, [handleNavigation]);
        (0, hooks_1.useEffect)(() => {
            if (navigate && addRecentActivity && navigationHandler && (accessToken || (0, environment_1.isPublicZone)(environment))) {
                (0, environment_1.debug)("[OCC] useEffect - plugin runtime init");
                navigateRef(navigate);
                (0, FrameworkPluginRuntime_1.getPluginRuntime)().init(navigationHandler, windowHeight => dispatch((0, reducer_1.setWindowHeight)(windowHeight)), addRecentActivity, analytics_1.trackAnalytics, exception => dispatch((0, reducer_1.setPluginUnhandledException)(exception)), () => openLanguageSelector(true), options => {
                    var _a, _b, _c, _d;
                    (0, chatbot_1.showChatbot)(((_b = (_a = plugin.options) === null || _a === void 0 ? void 0 : _a.isChatbotEnabled) !== null && _b !== void 0 ? _b : true) && !options.opened);
                    (0, ogl_1.showOgl)(((_d = (_c = plugin.options) === null || _c === void 0 ? void 0 : _c.isOglEnabled) !== null && _d !== void 0 ? _d : true) && !options.opened);
                }, { accessToken, searchText: searchValue, frameworkPlugins: finalPlugins, language, accountId }, { keepAliveOnClose: true });
            }
        }, [navigate, addRecentActivity, navigationHandler, accessToken]);
        // Change plugin
        (0, hooks_1.useEffect)(() => {
            var _a;
            (0, environment_1.debug)("[OCC] useEffect - change plugin", JSON.stringify({ plugin }));
            dispatch((0, reducer_1.setPlugin)(plugin));
            onContentSelected === null || onContentSelected === void 0 ? void 0 : onContentSelected(undefined);
            refreshHelpContent === null || refreshHelpContent === void 0 ? void 0 : refreshHelpContent((_a = plugin === null || plugin === void 0 ? void 0 : plugin.options) === null || _a === void 0 ? void 0 : _a.helpTopicId);
        }, [plugin]);
        // Substitute plugin url
        const params = (0, react_router_dom_1.useParams)();
        (0, hooks_1.useEffect)(() => {
            if (params && (pluginState === null || pluginState === void 0 ? void 0 : pluginState.substituteUrl)) {
                (0, environment_1.debug)("[OCC] useEffect - substitute plugin url", JSON.stringify({ params, pluginStateSubstituteUrl: pluginState.substituteUrl }));
                const { "*": splatParam } = params, nonSplatParams = __rest(params, ["*"]);
                let substitutedUrl = pluginState.substituteUrl;
                if (Object.keys(nonSplatParams).length > 0) {
                    substitutedUrl = Object.keys(nonSplatParams).reduce((acc, curr) => acc.replace(`:${curr}`, nonSplatParams[curr]), plugin.url);
                }
                if (substitutedUrl === null || substitutedUrl === void 0 ? void 0 : substitutedUrl.includes("/*")) {
                    substitutedUrl = substitutedUrl.replace("/*", `/${splatParam}`);
                }
                dispatch((0, reducer_1.setPluginUrl)(substitutedUrl));
            }
            if (params && (preFetch === null || preFetch === void 0 ? void 0 : preFetch.substituteUrl)) {
                (0, environment_1.debug)("[OCC] useEffect - substitute prefetch url", JSON.stringify({ params, preFetchSubstituteUrl: preFetch.substituteUrl }));
                const { "*": splatParam } = params, nonSplatParams = __rest(params, ["*"]);
                let substitutedUrl = preFetch.substituteUrl;
                if (Object.keys(nonSplatParams).length > 0) {
                    substitutedUrl = Object.keys(nonSplatParams).reduce((acc, curr) => acc.replace(`:${curr}`, nonSplatParams[curr]), plugin.url);
                }
                if (substitutedUrl === null || substitutedUrl === void 0 ? void 0 : substitutedUrl.includes("/*")) {
                    substitutedUrl = substitutedUrl.replace("/*", `/${splatParam}`);
                }
                dispatch((0, reducer_1.setPreFetchUrl)(substitutedUrl));
            }
        }, [params, pluginState === null || pluginState === void 0 ? void 0 : pluginState.substituteUrl, preFetch === null || preFetch === void 0 ? void 0 : preFetch.substituteUrl]);
        // Bootstrap plugin
        const { invoke: invokeBoostrap, result: { error: bootstrapError } } = (0, useMutation_1.useBootstrapMutation)(plugin === null || plugin === void 0 ? void 0 : plugin.bootstrapUrl, accessToken);
        (0, hooks_1.useEffect)(() => {
            if (bootstrap.url && bootstrap.params) {
                (0, environment_1.debug)("[OCC] useEffect - bootstrap", JSON.stringify({ bootstrap }));
                (0, environment_1.debug)("[OCC] bootstrap request sent.", bootstrap);
                invokeBoostrap(bootstrap.params, resp => {
                    (0, environment_1.debug)("[OCC] bootstrap response received.", resp);
                    dispatch((0, reducer_1.setPluginUrl)(resp.bootstrapUrl));
                });
            }
        }, [bootstrap]);
        // Prefetch plugin
        const { result: preFetchResult, error: preFetchError, loading: preFetchLoading } = (0, useQuery_1.usePreFetchQuery)(preFetch.url, accessToken);
        (0, hooks_1.useEffect)(() => {
            if (preFetchResult) {
                (0, environment_1.debug)("[OCC] useEffect - preFetchResult", JSON.stringify({ preFetchResult }));
                (0, environment_1.debug)("[OCC] prefetch succeeded.");
                if (preFetchResult.type === "application/pdf") {
                    dispatch((0, reducer_1.setPluginLiftSandbox)(true));
                }
                dispatch((0, reducer_1.setPluginUrl)(URL.createObjectURL(preFetchResult)));
            }
        }, [preFetchResult]);
        // Open plugin
        (0, hooks_1.useEffect)(() => {
            if (pluginState.url) {
                (0, environment_1.debug)("[OCC] useEffect - open plugin", JSON.stringify({ pluginState }));
                const pluginFrame = createFrame("content", pluginState.options.liftSandbox);
                openPlugin(pluginState.url, pluginState.path, pluginState.allowedOrigins)
                    .finally(() => dispatch((0, reducer_1.setContentLoaded)()));
                return () => {
                    (0, environment_1.debug)("[OCC] disposing plugin");
                    if (pluginState.options.liftSandbox) {
                        dispatch((0, reducer_1.setPluginLiftSandbox)(false));
                    }
                    pluginFrame === null || pluginFrame === void 0 ? void 0 : pluginFrame.remove();
                    setSearchValue(undefined);
                    (0, FrameworkPluginRuntime_1.getPluginRuntime)().disposeService();
                };
            }
        }, [pluginState === null || pluginState === void 0 ? void 0 : pluginState.url]);
        // Runtime context
        const { user, openLanguageSelector } = (0, hooks_1.useContext)(userProvider_1.UserContext);
        const language = user === null || user === void 0 ? void 0 : user.defaultLanguage;
        const accountId = (_a = user === null || user === void 0 ? void 0 : user.profile) === null || _a === void 0 ? void 0 : _a.defaultAccountId;
        (0, hooks_1.useEffect)(() => {
            (0, environment_1.debug)("[OCC] useEffect - runtime context change", JSON.stringify({ language, isContentLoaded, accountId, searchValue }));
            dispatch((0, reducer_1.setLanguage)(language));
            if (isContentLoaded) {
                (0, FrameworkPluginRuntime_1.getPluginRuntime)().updateRuntimeContext({ language, accountId, searchText: searchValue });
                // 'setLanguage' call can be removed when plugins uptake the new communication version
                (0, FrameworkPluginRuntime_1.getPluginRuntime)().setLanguage(language);
            }
        }, [language, accountId, searchValue, isContentLoaded]);
        // Location change
        const location = (0, react_router_dom_1.useLocation)();
        (0, hooks_1.useEffect)(() => {
            var _a;
            if (plugin) {
                if (((_a = plugin === null || plugin === void 0 ? void 0 : plugin.url) === null || _a === void 0 ? void 0 : _a.includes("/*")) && location.pathname.startsWith(pluginState.path)) {
                    (0, environment_1.debug)("[OCC] useEffect - location change for splat plugin config", JSON.stringify({ location, plugin }));
                    dispatch((0, reducer_1.setPlugin)(plugin));
                }
                else {
                    (0, environment_1.debug)("[OCC] useEffect - location change", JSON.stringify({ location, plugin }));
                    routePlugin(plugin.path, location.pathname);
                }
                pluginRef(plugin);
            }
        }, [plugin, location.pathname]);
        (0, hooks_1.useEffect)(() => {
            var _a;
            if (pluginState.path === location.pathname || ((_a = plugin === null || plugin === void 0 ? void 0 : plugin.url) === null || _a === void 0 ? void 0 : _a.includes("/*")) && location.pathname.startsWith(pluginState.path)) {
                (0, environment_1.debug)("[OCC] useEffect - location search params change", JSON.stringify({ location, plugin, pluginState }));
                // seems like only location search is changed, the plugin remained the same, but we have to start all over again
                dispatch((0, reducer_1.setPlugin)(plugin));
            }
        }, [location.search]);
        // Error
        (0, hooks_1.useEffect)(() => {
            if (isError || bootstrapError || preFetchError) {
                (0, environment_1.debug)("[OCC] useEffect - error", JSON.stringify({ isError, bootstrapError, preFetchError }));
                dispatch((0, reducer_1.setError)(isError ? "invalidRoute" : "fatal"));
                dispatch((0, reducer_1.setContentLoaded)());
            }
        }, [isError, bootstrapError, preFetchError]);
        const configOverride = (0, useConfigOverride_1.useConfigOverride)();
        const showLabel = (0, hooks_1.useMemo)(() => {
            var _a, _b;
            return (_b = (_a = configOverride.plugins) === null || _a === void 0 ? void 0 : _a.some(p => p.name === (plugin === null || plugin === void 0 ? void 0 : plugin.name))) !== null && _b !== void 0 ? _b : false;
        }, [configOverride, plugin]);
        const HomePage = importHomePage(environment.name);
        return ((0, jsx_runtime_1.jsxs)("div", { id: "content", className: `oj-flex-item oj-flex ${showLabel ? "override-border" : "no-border"}`, style: { width: "100%", minHeight: windowHeight }, children: [pluginUnhandledException &&
                    (0, jsx_runtime_1.jsx)(alertMessage_1.AlertMessage, { message: errorTitle, details: errorMessage }), plugin &&
                    (0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [showLabel && (0, jsx_runtime_1.jsx)("div", { className: "override-label", children: (_c = (_b = configOverride.plugins) === null || _b === void 0 ? void 0 : _b.filter(p => p.name === (plugin === null || plugin === void 0 ? void 0 : plugin.name))[0]) === null || _c === void 0 ? void 0 : _c.url }), menuOpened && (0, jsx_runtime_1.jsx)("div", { className: "oj-component-overlay" }), preFetchLoading && (0, jsx_runtime_1.jsx)(spinner_1.Spinner, {})] }), !plugin && !error &&
                    (0, jsx_runtime_1.jsx)(compat_1.Suspense, { fallback: (0, jsx_runtime_1.jsx)(spinner_1.Spinner, {}), children: (0, jsx_runtime_1.jsx)(HomePage, {}) }), error &&
                    (0, jsx_runtime_1.jsx)(errorPage_1.ErrorPage, { errorType: error.type, environment: environment })] }));
    };
    exports.Content = Content;
    const ErrorPageContent = ({ environment, navigateRef, menuOpened, handleNavigation }) => {
        return ((0, jsx_runtime_1.jsx)(exports.Content, { plugin: undefined, finalPlugins: undefined, environment: environment, accessToken: undefined, navigateRef: navigateRef, pluginRef: (_) => { }, menuOpened: menuOpened, isError: true, handleNavigation: handleNavigation }));
    };
    exports.ErrorPageContent = ErrorPageContent;
});
