define(["require", "exports", "preact/hooks", "../utils/environment", "../utils/traceability"], function (require, exports, hooks_1, environment_1, traceability_1) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    exports.useHelpTopicQuery = exports.usePreFetchQuery = exports.useNotificationQuery = exports.useAnnouncementsQuery = exports.useRecentActivityQuery = exports.useUserQuery = exports.useEnvironmentQuery = exports.useNavigationsQuery = exports.isPluginItem = exports.isLinkItem = exports.usePluginsQuery = exports.HttpException = void 0;
    class HttpException extends Error {
        constructor(path, status) {
            super(`HTTP call to ${path} has been failed with ${status}`);
            this.path = path;
            this.status = status;
        }
    }
    exports.HttpException = HttpException;
    const forcedDummyAccessToken = "TOKEN";
    const useQuery = (path, options) => (accessToken, queryOptions) => {
        const [loading, setLoading] = (0, hooks_1.useState)(false);
        const [error, setError] = (0, hooks_1.useState)();
        const [result, setResult] = (0, hooks_1.useState)();
        const [refresh, setRefresh] = (0, hooks_1.useState)(false);
        const [previousPath, setPreviousPath] = (0, hooks_1.useState)("");
        if (path !== previousPath) {
            setError(undefined);
            setResult(undefined);
            setRefresh(false);
            setPreviousPath(path);
        }
        const refreshCallback = (0, hooks_1.useCallback)(() => setRefresh(true), [setRefresh]);
        (0, hooks_1.useEffect)(() => {
            if (path && (accessToken && !error && !result || accessToken && (error || result) && refresh)) {
                setRefresh(false);
                setLoading(true);
                fetch(path, {
                    headers: {
                        "Authorization": `Bearer ${accessToken}`,
                        [traceability_1.traceHeaderName]: (0, traceability_1.createTraceHeaderValue)(accessToken !== forcedDummyAccessToken ? accessToken : undefined)
                    }
                }).then(response => {
                    var _a;
                    if (!response.ok) {
                        if (response.status === 401) {
                            (_a = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.setAuthenticationError) === null || _a === void 0 ? void 0 : _a.call(queryOptions, true);
                        }
                        throw new HttpException(path, response.status);
                    }
                    return (options === null || options === void 0 ? void 0 : options.blobRequested) ? response.blob() : response.json();
                })
                    .then(setResult)
                    .catch(setError)
                    .finally(() => {
                    setLoading(false);
                });
            }
        }, [path, accessToken, error, result, refresh]);
        return { loading, result, error, refresh: refreshCallback };
    };
    exports.usePluginsQuery = useQuery(`${environment_1.apiUrl}/plugins`);
    const isLinkItem = (obj) => obj != null && obj.path !== undefined;
    exports.isLinkItem = isLinkItem;
    const isPluginItem = (obj) => obj != null && obj.pluginName !== undefined;
    exports.isPluginItem = isPluginItem;
    const useNavigationsQuery = (navigationsSet) => useQuery(`${environment_1.apiUrl}/navigations?type=${navigationsSet}`);
    exports.useNavigationsQuery = useNavigationsQuery;
    const useEnvironmentQuery = () => useQuery(`/config/environment.json?v=${process.env.VERSION}`)(forcedDummyAccessToken);
    exports.useEnvironmentQuery = useEnvironmentQuery;
    exports.useUserQuery = useQuery(`${environment_1.apiUrl}/user`);
    exports.useRecentActivityQuery = useQuery(`${environment_1.apiUrl}/recent-activities`);
    const useAnnouncementsQuery = () => useQuery(`/config/announcements.json?v=${process.env.VERSION}`)("TOKEN");
    exports.useAnnouncementsQuery = useAnnouncementsQuery;
    exports.useNotificationQuery = useQuery(`${environment_1.apiUrl}/notifications`);
    const usePreFetchQuery = (path, accessToken) => {
        (0, environment_1.debug)("[OCC] usePreFetchQuery - prefetch", path);
        return useQuery(path, { blobRequested: true })(accessToken);
    };
    exports.usePreFetchQuery = usePreFetchQuery;
    const useHelpTopicQuery = (helpTopicId, languageCode) => {
        const path = helpTopicId ? `${environment_1.apiUrl}/help-content/${helpTopicId}?languageCode=${languageCode}` : undefined;
        return useQuery(path);
    };
    exports.useHelpTopicQuery = useHelpTopicQuery;
});
